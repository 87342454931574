function Feature2(props) {

    return (
        <section className="feature2">
            {
                props.step!=undefined?
                <span>
                    <h4 className="stepTitle2">
                        {props.step.title}
                    </h4>
                    <div className="featureDescription2">
                        {props.step.description}
                    </div>
                </span>
                :
                <div>

                </div>
            }

            
        </section>
    );
  }
  
  export default Feature2;