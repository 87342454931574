import { BrowserRouter, Routes, Route } from 'react-router-dom';

import './App.css';

import './resources/styling/style.css'
import NavBar from './components/NavBar';
import Home from './components/HomePage';
import MobileMenu from './components/MobileMenu';

function App() {
  return (
    <div className='PB'>
      
    <div className="App">
    <NavBar/>
    <MobileMenu/>
        <BrowserRouter>
        <Routes>
        
        <Route exact path="/" element={< Home language="EN"/>} />
        <Route exact path="/AR" element={< Home language="AR"/>} />
        <Route exact path="/FR" element={< Home language="FR"/>} />

        </Routes>
      </BrowserRouter>
    </div>
    </div>
    
  );
}

export default App;
