import LandingSection2 from './LandingSection2'
import HowItWorks from './HowItWorks'

import Contact from './Contact'
import WhoAreWe from './WhoAreWe';
import Services from './Services';


function Home() {
  
    return (
      
            <div className='Home'>
                      <LandingSection2/>
                      <WhoAreWe/>
                      <Services/>
                      <HowItWorks/>
                      <Contact/>
            </div>
    );
  }
  
  export default Home;