import Unifayat from "../resources/assets/Unifayat1.png"

function NavBar() {
    window.addEventListener('scroll', function() {
        const navbar = document.querySelector('.NavBar')
        const navbarLinks = document.getElementsByClassName('navbarLinks')
        if (window.scrollY > 0) {
          navbar.style.backgroundColor = 'var(--primary-color-transparent)';
          Array.from(navbarLinks).forEach(function (element) {
            element.classList.add('lightLink')
          });
        } 
        else {
          navbar.style.backgroundColor = 'transparent'; 
          Array.from(navbarLinks).forEach(function (element) {
            element.classList.remove('lightLink')
          });
        }
      });
    return (
        
        <ul className='NavBar' id="NavBar">
            <span>
                <img className='Logo' src={Unifayat}/>
                
            </span>
            <span id="NavBarItems" className="NavBarItems">
            <li>
              <a className="navbarLinks" href="/">Home</a>  
            </li>
            <li>
              <a className="navbarLinks" href="#WhoAreWe">Who Are We ?</a>  
            </li>
            <li>
              <a className="navbarLinks" href="#Services">Our Services</a>  
            </li>
            <li>
              <a className="navbarLinks" href="#HowItWorks">How It Works?</a>  
            </li>
            </span>
        </ul>
        
    );
  }
  
  export default NavBar;