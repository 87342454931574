import { useEffect,useState } from 'react';
import bg from '../resources/assets/Unifayat1.png'
import rightOrnament from '../resources/assets/rightOrnament.png'
import rightOrnamentM from '../resources/assets/rightOrnamentM.png'

import {english,arab} from '../resources/Language'

function LandingSection2() {
  const [text,setText]=useState([])
  useEffect(()=>{
          setText(english);
  },[text])
    return (
            <div className="LandingSection2">
              <img className ="rightOrnament" src={rightOrnament}/>
              <img className ="rightOrnamentM" src={rightOrnamentM}/>
              {text.landingSection!==undefined?
                <div className='heroSection'>
                  <div className='ImageSection'>
                    {/*<img  className='LandingSectionImage' src={bg}/>*/}
                  </div>
                  <div className='TextSection'>
                  <div className='PB_header'><span className='greenU'>U</span>{text.landingSection.companyName}</div>
                  <div className='PB_headline'>
                    {text.landingSection.headline}
                  </div>
                  <div className='PB_subHeadline'>
                    {text.landingSection.subHeadline}
                  </div>
                  <button className="ReadMoreAbtUs ReadMoreAbtUsAnimate"><a href="/AboutUs">{text.landingSection.getStarted}</a></button>
                  </div>

                </div>
              :
              <div></div>}

            </div>
    );
  }
  
  export default LandingSection2;