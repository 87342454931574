export const english=
    {
        landingSection:{
            companyName:"nifayat",
            headline:"Revolutionize Waste Management",
            subHeadline:"Let's turn waste into resource!",
            getStarted:"Join The Revolution Now!",
        }
    };
export const arab=
{
    landingSection:{
        companyName:"Purple Bees",
        description:"دافعنا ينبع من رغبتنا في صياغة كل مشروع كتحفة استثنائية، مصممة بعناية فائقة لتناسب احتياجاتك الفردية",
        getStarted:"ابدأ الآن",
    }
};

