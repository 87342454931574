import phone from "../resources/assets/Phone.png"
import instagram from "../resources/assets/Instagram.png"
import facebook from "../resources/assets/Facebook.png"
import linkedin from "../resources/assets/LinkedIn.png"
import email from "../resources/assets/Email.png"

function Contact() {
    return (
        <section className="contact">
            <h3>Contact Us</h3>
            <div className="ContactInfo">
                <div className="ContactInfoData">
                    <div className="IconContainer">
                        <img src={email}/>
                    </div>
                    <div className="ContactInfoText">
                        <p><a className="LightTitle" href="mailto:contact@purplebeescom.co">contact@purplebeescom.co</a></p>
                    </div>
                </div>
                <div className="ContactInfoData">
                    <div className="IconContainer">
                        <img src={phone}/>
                    </div>
                    <div className="ContactInfoText">
                        <p> <a className="LightTitle" href="tel:+212 808 575 346">+212 808 575 346</a></p>
                    </div>
                </div>
                <div className="ContactInfoData">
                    <div className="IconContainer">
                        <img src={instagram} href="https://www.instagram.com/unifayat"/>
                    </div>
                    <div className="ContactInfoText">
                        <p><a className="LightTitle" href="https://www.instagram.com/unifayat">@unifayat</a></p>
                    </div>
                </div>
                <div className="ContactInfoData">
                    <div className="IconContainer">
                        <img src={linkedin} href="https://www.linkedin.com/company/purple-bees-com/"/>
                    </div>
                    <div className="ContactInfoText">
                        <p><a className="LightTitle" href="https://www.linkedin.com/company/purple-bees-com/">Purple Bees</a></p>
                    </div>
                </div>
                <div><h5>All Rights Reserved To PurpleBeesCom</h5></div>
            </div>


        </section>
    );
  }
  
  export default Contact;