import Feature2 from "./Feature2";
import lefty from '../resources/assets/lefty.png';
import righty from '../resources/assets/righty.png';
import up from '../resources/assets/up.png';
import down from '../resources/assets/down.png';

//Swipper
import React from "react";

import {  Swiper, SwiperSlide } from 'swiper/react';
import {  Autoplay, Navigation, Pagination } from 'swiper/modules';
//import SwiperCore, { Autoplay } from 'swiper';

import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper-bundle.css';
import 'swiper/css';
import 'swiper/css/navigation'; // Import additional modules if needed
import 'swiper/css/pagination';
// Import Swiper core

// Import Swiper styles
import 'swiper/swiper-bundle.css';

// Install Swiper modules

//SwiperCore.use([Autoplay]);

import unifayat from '../resources/assets/Unifayat_white.png';
import { useEffect, useState } from 'react';
function HowItWorks() {


    return (
        <div className="HowItWorks" id='HowItWorks'>
            <h1 className='DarkTitle'>How Does It Work?</h1>
            {/*
            <h2 className='DarkTitle'>As A HouseHold :</h2>
            <div className='featuresContainer2'>
                
                {individualSteps.map(step=>
                    <div>
                        <Feature step={step}/>
                    </div>
                    )}

            </div>
            <h2  className='DarkTitle'>As A Collector :</h2>
            <div className='featuresContainer2'>
                
                {collectorsSteps.map(step=>
                    <div>
                        <Feature step={step}/>
                    </div>
                    )}

            </div>
            <h2  className='DarkTitle'> As A Recycling Company :</h2>
            <div className='featuresContainer2'>
                
                {companiesSteps.map(step=>
                    <div>
                        <Feature step={step}/>
                    </div>
                    )}

            </div>
            */}
            <div className="hiw-desktop">
                <div className='featuresContainer2'>
                    <Feature2 step={{title:"Households",description:"Sorts their trash (plastic/glass…etc.)",image:""}}/>
                    <div className="feature3">
                        <img src={lefty}/>
                    </div>
                    <Feature2 step={{title:"Sell Them on Unifayat",description:"And Get Points That Would Be Converted To Rewards (Coupons...)",image:""}}/>   
                    <div className="feature3">
                        <img src={lefty}/>
                    </div>
                    <Feature2 step={{title:"Collectors",description:"Get Notified Of The Availability Of A New Pick Up ,Then They Scheduale Their PickUp and Their Drop-Off ",image:""}}/>   
                </div>
                {/** */}
                <div className='featuresContainer2'>
                    <div className="feature22">
                        <img src={up}/>
                    </div>
                    <div className="feature33">

                    </div>
                    <div className="feature22">

                    </div>
                    <div className="feature33">
                        
                    </div>
                    <div className="feature22">
                    <img src={down}/>
                    </div>
                </div>
                {/** */}
                <div className='featuresContainer2'>
                
                <Feature2 step={{title:"Buy",description:"Recycled Products Helping In The Protection Of The Environement",image:""}}/>

                    <div className="feature3">
                    </div>
                    <div className="feature2 ft2-wimg">
                        <img src={unifayat}/>
                    </div>
                    <div className="feature3">
                    </div>
                    <Feature2 step={{title:"Transport",description:"Waste To The Destination",image:""}}/>
                </div>
                {/** */}
                <div className='featuresContainer2'>
                <div className="feature22">
                        <img src={up}/>
                    </div>
                    <div className="feature33">

                    </div>
                    <div className="feature22">

                    </div>
                    <div className="feature33">
                        
                    </div>
                    <div className="feature22">
                    <img src={down}/>
                    </div>              
                </div>
                {/** */}
                <div className='featuresContainer2'>
                    <Feature2 step={{title:"Sell",description:"Recycled Products on Unifayat",image:""}}/>
                    <div className="feature3">
                        <img src={righty}/>
                    </div>
                    <Feature2 step={{title:"Recycling Companie/Waste Sorting Companies",description:"",image:""}}/>   
                    <div className="feature3">
                        <img src={righty}/>
                    </div>
                    <Feature2 step={{title:"Get Points",description:"That Would Later be Converted To Cash",image:""}}/>   
                </div>
                </div>
                <div className="hiw-mobile" data-swiper-autoplay="1000">
                <Swiper
                    modules={[Pagination, Navigation, Autoplay]}
                    spaceBetween={50}
                    slidesPerView={1}
                    speed={2000}
                    autoplay={{ delay: 1500}}
                    loop={true}
                    /*navigation={true}*/
                >
                    <SwiperSlide>
                        <div className="swiper-slide-content">
                        <Feature2 step={{title:"Households",description:"Sorts their trash (plastic/glass…etc.)",image:""}}/>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="swiper-slide-content">
                        <Feature2 step={{title:"Sell Them on Unifayat",description:"And Get Points That Would Be Converted To Rewards (Coupons...)",image:""}}/>   
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="swiper-slide-content">
                        <Feature2 step={{title:"Collectors",description:"Get Notified Of The Availability Of A New Pick Up ,Then They Scheduale Their PickUp and Their Drop-Off ",image:""}}/>   
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="swiper-slide-content">
                        <Feature2 step={{title:"Transport",description:"Waste To The Destination",image:""}}/>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="swiper-slide-content">
                        <Feature2 step={{title:"Get Points",description:"That Would Later be Converted To Cash",image:""}}/>   
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="swiper-slide-content">
                        <Feature2 step={{title:"Recycling Companie/Waste Sorting Companies",description:"",image:""}}/>   
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="swiper-slide-content">
                        <Feature2 step={{title:"Sell",description:"Recycled Products on Unifayat",image:""}}/>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="swiper-slide-content">
                        <Feature2 step={{title:"Buy",description:"Recycled Products Helping In The Protection Of The Environement",image:""}}/>
                        </div>
                    </SwiperSlide>
                </Swiper>
                </div>
        </div>
                

    );
  }
  
  export default HowItWorks;