import menuIcon from "../resources/assets/mnu.svg"

function MobileMenu() {
    const onMenuClick=()=>{
        const menu=document.getElementById('MobileNavBarItems')
        menu.classList.toggle('hide')
    }
    return (
        
        <ul className='MobileNavBar' id="MobileNavBar">
            <div className="MobileNavBarMenu">
                <img className='MenuIcon' src={menuIcon} onClick={onMenuClick}/>
            </div>
            <div className="MobileNavBarItems hide" id="MobileNavBarItems">
            <hr/>
            <li>
            <h1 className="LightTitle"><a className="LightTitle" href="/">Home</a></h1>
            </li>
            <hr/>
            <li>
            <h1 className="LightTitle"><a className="LightTitle" href="#WhoAreWe">Who Are We ?</a></h1>
            </li>
            <hr/>
            <li>
            <h1 className="LightTitle"><a className="LightTitle" href="#Services">Our Services</a></h1>
            </li>
            <hr/>
            <li>
            <h1 className="LightTitle"><a className="LightTitle" href="#HowItWorks">How It Works ?</a></h1>
            </li>
            <hr/>
            </div>
        </ul>
        
    );
  }
  
  export default MobileMenu;