import listing from '../assets/listing.png'
import earnPoints from '../assets/earnPoints.png'
import rate from '../assets/rate.png'
import rating from '../assets/rating.png'
import pickup from '../assets/pickup.png'
import sustain from '../assets/sustain.png'
import secure from '../assets/secure.png'
import userfriendly from '../assets/userfriendly.png'
import Coordination from '../assets/Coordination.png'
import community from '../assets/Community.png'
import Listing from '../assets/Listing1.png'
import bids from '../assets/bids.png'
import support from '../assets/support.png'
import rewards from '../assets/rewards.png'
import PartnerShip from '../assets/PartnerShip.png'
export const individualSteps=[
    {
        title:"1-List Your Waste",
        description:"List Your Sorted Waste ",
        image:listing
    },
    {
        title:"2-Receive Bids from Buyers",
        description:"Receive Bids From Our Partners and Choose What Suits You The Best",
        image:bids
    },
    {
        title:"3-Waste Pickup and Transportation",
        description:"Our Collector Will Come And Pick Your Waste to Be Transported To The Recycling Company Who Bought it.",
        image:pickup,
    },
    {
        title:"4-Earn Points and Convert them",
        description:"With Each Sale You Earn Points That You Can Convert To Coupons , Phone Recharge Or Discounts ",
        image:earnPoints
    },
    {
        title:"5-Rate Your Experience",
        description:"Rate Your Experience and Help Us improve Our Service",
        image:rate
    },
    {
        title:"6-Repeat and Contribute to Sustainability",
        description:"Repeat the process to contribute to a sustainable future and efficient waste management.",
        image:sustain
    },

];
export const collectorsSteps=[
    {
        title:"1-Browse Available Jobs",
        description:"Browse through the available waste pickup jobs posted by households on the platform.",
        image:""
    },
    {
        title:"2-Schedule Collections",
        description:"Scheduale Your PickUp and Your Drop-Off",
        image:""
    },
    {
        title:"3-Waste Pickup and Transportation",
        description:"Rate the quality of the waste then Pick it up from households and transport it to designated recycling companies.",
        image:""
    },
    {
        title:"4-Rate Services",
        description:"After each successful waste pickup, both households and recycling companies can rate your services based on the quality of the delivery.",
        image:""
    },
    {
        title:"5-Accumulate Points And Convert Them To Cash",
        description:"Earn points for every successful waste delivery, Then Convert Them To Cash",
        image:""
    },
];
export const companiesSteps=[
    {
        title:"1-Browse Available Waste",
        description:"rowse and bid on waste listings from households, specifying the types of waste you are equipped to handle.",
        image:""
    },
    {
        title:"2-Submit Payments",
        description:" Once you wins a bid, make payments through the platform for the purchased waste.",
        image:""
    },
    {
        title:"3-Schedule Collections",
        description:"Coordinate with collectors to schedule the pickup of waste from households.",
        image:""
    },
    {
        title:"4-Rate Collectors",
        description:"After each successful waste delivery, you can rate the services of the collectors based on the quality of the received waste.",
        image:""
    },
    {
        title:"5-Contribute to Sustainability",
        description:" By actively participating in the marketplace, You contribute to a more sustainable and circular waste management ecosystem.",
        image:""
    },

];
export const services=[
    {
        title:"Waste Listing and Bidding",
        description:"rowse and bid on waste listings from households, specifying the types of waste you are equipped to handle.",
        image:Listing
    },
    {
        title:"Secure Transactions",
        description:" Once you wins a bid, make payments through the platform for the purchased waste.",
        image:secure
    },
    {
        title:"Collector Coordination",
        description:"Coordinate with collectors to schedule the pickup of waste from households.",
        image:Coordination
    },
    {
        title:"User-Friendly Platform",
        description:"After each successful waste delivery, you can rate the services of the collectors based on the quality of the received waste.",
        image:userfriendly
    },
    {
        title:"Rating and Feedback System",
        description:" By actively participating in the marketplace, You contribute to a more sustainable and circular waste management ecosystem.",
        image:rating
    },
    {
        title:"Community Engagement",
        description:" By actively participating in the marketplace, You contribute to a more sustainable and circular waste management ecosystem.",
        image:community
    },
    {
        title:"Rewards Program",
        description:" By actively participating in the marketplace, You contribute to a more sustainable and circular waste management ecosystem.",
        image:rewards
    },
    {
        title:"Customer Support",
        description:" By actively participating in the marketplace, You contribute to a more sustainable and circular waste management ecosystem.",
        image:support
    },
    {
        title:"Partnership Opportunities",
        description:" By actively participating in the marketplace, You contribute to a more sustainable and circular waste management ecosystem.",
        image:PartnerShip
    },

];