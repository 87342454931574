
import Feature from "./Feature";
import { useEffect, useState } from 'react';
import { services } from '../resources/Data/GetData';
function Services() {


    return (
        <div className="Services" id='Services'>
            
        <section>
        <h1>Our Services</h1>
        
        <div className='featuresContainer'>
                
                {services.map(ser=>
                    <div>
                        <Feature step={ser}/>
                    </div>
                    )}

            
        </div>
    </section>
        
            
        </div>
                

    );
  }
  
  export default Services;