function Feature(props) {

    return (
        <section className="feature">
            {
                props.step!=undefined?
                <div>
                    <div className="stepImage">
                        <img src={props.step.image}/>
                    </div>
                    <h3 className="stepTitle">
                        {props.step.title}
                    </h3>
                    <div className="featureDescription">
                        {props.step.description}
                    </div>
                </div>
                :
                <div>

                </div>
            }

            
        </section>
    );
  }
  
  export default Feature;