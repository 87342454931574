import mission from '../resources/assets/Mission.jpg'
import values from '../resources/assets/Values.jpg'
import involved from '../resources/assets/involved.jpg'
import stewardship from '../resources/assets/stewardship.jpg'

import Feature from "./Feature";
import { useEffect, useState } from 'react';
function WhoAreWe() {


    return (
        <div className="WhoAreWe" id='WhoAreWe'>
        
        <div className='waw-welcome'>
        <h4> 
            Unifayat revolutionizes waste management, connecting households, collectors, and recycling companies for positive change through technology and collaboration.
        </h4>
        </div>
<div className="waw-desc">
    <div className='waw-mission'>
        <div className='BackgroundImageContainer'>
            <img src={mission}></img>
        </div>

        <div className="waw-text">
            <h3>Our Mission</h3>
            <div className='waw-description'>
            Our mission is to create a waste marketplace that streamlines disposal while promoting sustainability, community engagement, and environmental impact. Join us in building a greener, more circular waste management ecosystem.
            </div>
        </div>
    </div>
<div className='waw-values'>
<div className='BackgroundImageContainer'>
        <img src={values}></img>
    </div>
    <div className="waw-text">
        <h3>Values and Commitments</h3>
        <div className='waw-description'>
        Unifayat values transparency, innovation, and environmental responsibility, guiding us daily for positive impacts.        </div>
</div>
</div>
<div className='waw-stewardship'>
<div className='BackgroundImageContainer'>
        <img src={stewardship}></img>
    </div>
    <div className="waw-text">
        <h3>Environmental Stewardship</h3>
        <div className='waw-description'>
        We minimize our ecological footprint through responsible waste management, advanced technologies, eco-conscious collaborations, and sustainable solutions, aiming to preserve the planet for future generations.
        </div>
    </div>
</div>
<div className='waw-involved'>
    <div className='BackgroundImageContainer'>
        <img src={involved}></img>
    </div>
    <div className="waw-text">
        <h3>Get Involved</h3>
        <div className='waw-description'>
        Join Unifayat to make a difference! Whether you're a household, collector, or recycling company, find your place in our community committed to creating positive change and a more sustainable world.        </div>
    </div>
</div>
</div>
            
            </div>
                

    );
  }
  
  export default WhoAreWe;